import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MenuItems from './items';

import './style.css';

export default function Menu() {
  const data = useStaticQuery(graphql`
    query menuQuery {
      wpMenu(databaseId: { eq: 2 }) {
        ...MenuData
      }
    }
  `);
  return (
    <ul
      itemScope
      itemType="https://www.schema.org/SiteNavigationElement"
      style={{
        display: 'flex',
        alignItems: 'center',
        verticalAlign: `top`,
        width: `auto`,
        margin: 0,
      }}
    >
      <MenuItems menu={data.wpMenu.menuItems.nodes} />
    </ul>
  );
}
