import React from 'react';

import Link from '../Links';

import { MainDark } from '../../utils/variables';
import DropdownArrow from '../../images/icon-dropdown.svg';

export default class MenuItems extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      activeMenu: false,
      menuKey: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showDropdown(index) {
    if (this._isMounted) {
      this.setState({
        activeMenu: true,
        menuKey: index,
      });
    }
  }

  hideDropdown() {
    if (this._isMounted) {
      this.setState({
        activeMenu: false,
        menuKey: null,
      });
    }
  }

  render() {
    const { activeMenu, menuKey } = this.state;
    const { menu, mobile, closeLightbox } = this.props;
    return menu
      .filter(x => x.parentId === null)
      .map((item, index) => (
        <li
          key={item.label}
          onMouseEnter={() => this.showDropdown(index)}
          onTouchStart={() => activeMenu !== true && this.showDropdown(index)}
          onMouseLeave={() => this.hideDropdown(index)}
          className={`menuItem ${mobile ? `mobile ` : ``}${
            item.childItems.nodes.length > 0 ? `dropdown ` : ``
          }${item.cssClasses}`}
          style={{
            flex: !mobile && `0 0 auto`,
            margin: !mobile && index !== 0 && `0 0 0 1.875rem`,
            backgroundImage:
              item.childItems.nodes.length > 0
                ? `url(${DropdownArrow})`
                : `none`,
            backgroundPosition: `right 0px top ${mobile ? '12px' : '55%'}`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `6px`,
            position: `relative`,
          }}
          itemProp="name"
          // role="presentation"
        >
          {item.url.includes('http') ? (
            <a href={[item.url]} target={item.target} rel="noopener noreferrer">
              {item.label}
            </a>
          ) : (
            <Link
              to={item.url}
              content={item.label}
              onClick={e => mobile && closeLightbox(e)}
            />
          )}
          {item.childItems.nodes.length > 0 && activeMenu && index === menuKey && (
            <ul
              className={`dropdown${mobile ? ` mobile` : ``}`}
              style={!mobile ? dropdownDesktop : dropdownMobile}
            >
              {item.childItems.nodes.map(child => (
                <li key={child.label} className={child.classes} itemProp="name">
                  <Link
                    to={child.url}
                    content={child.label}
                    onClick={e => mobile && closeLightbox(e)}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ));
  }
}

const dropdownDesktop = {
  position: 'absolute',
  top: `60px`,
  left: `0`,
  margin: 0,
  width: `300px`,
  textAlign: `left`,
  zIndex: 100,
  animation: `dropdown 0.3s forwards`,
  backgroundColor: MainDark,
};

const dropdownMobile = {
  padding: `0 0 0 0.9375rem`,
  overflow: `hidden`,
  animation: `mobileInner 0.3s forwards`,
};
